<template>
    <div class="h-full">
        <div class="wrapper mx-auto lg:w-3/4 relative px-4">
            <div class="flex justify-center my-10" v-if="['----', 'home'].includes($route.name)">
                <router-link :to="{ name: 'onsite' }"><img src="img/css/logo.svg?3" /></router-link>
            </div>
            <div class="flex justify-between items-center px-4" style="height: 100px" v-else>
                <div>
                    <router-link :to="{ name: 'onsite' }"><img class="w-40" src="img/css/logo.svg?3" /></router-link>
                    <p class="text-xs text-gray-400 mt-1">v{{ version }}</p>
                </div>

                <language-picker v-if="!['onsite-languageSelect'].includes($route.name)" />
            </div>

            <div class="w-full bg-white px-4 py-6 md:px-8 md:py-7 xl:px-12 md:py-10 rounded-lg md:shadow-lg">
                <transition mode="out-in" name="fade-in" appear>
                    <router-view />
                </transition>
            </div>

            <!--			<div class="flex justify-between mt-5">-->
            <!--				<router-link tag="button" :to="{name:'UiSystem'}" class="btn fixed bottom-0 right-0 mr-5 mb-5">UI System</router-link>-->
            <!--			</div>-->
        </div>

        <safety-icons v-show="showIcons" />
    </div>
</template>
<script>
import LanguagePicker from "../components/LanguagePicker";
import SafetyIcons from "../components/SafetyIcons";
import { ACTION_SET_ANONYMOUS_LOGIN_INFO, ACTION_SET_ONSITE_SELECTION } from "../store/constants";
import * as axios from "axios";

export default {
    name: "Onsite",
    components: { SafetyIcons, LanguagePicker },
    data() {
        return {
            timeoutRef: 0,
            inactivityInterval: 120000,
            pollInterval: 10 * 60 * 1000,
            pollRef: 0,
            version: "",
        };
    },
    watch: {
        $route(to, from) {
            // console.log("Gert: reload", to.name, from.name);
            if (
                to.name === "onsite-languageSelect" &&
                (from.name === "onsite-registrationComplete" ||
                    from.name === "onsite-registrationIncomplete" ||
                    from.name === "onsite-checkOut")
            ) {
                location.reload();
            }
        },
    },

    mounted() {
        document.addEventListener("touchstart", this.stopIdleTimer);
        document.addEventListener("touchend", this.startIdleTimer);

        setTimeout(this.poll, this.pollInterval);
    },

    beforeDestroy() {
        document.removeEventListener("touchstart", this.stopIdleTimer);
        document.removeEventListener("touchend", this.startIdleTimer);

        clearTimeout(this.pollRef);
        clearTimeout(this.timeoutRef);
    },

    created() {
        // console.log(localStorage.onsiteselectionV3, localStorage.onsitelogin);

        // Check for authentication and settings in localstorage
        if (localStorage.onsiteselectionV3)
            this.$store.commit(ACTION_SET_ONSITE_SELECTION, JSON.parse(localStorage.onsiteselectionV3));
        if (localStorage.onsitelogin) {
            this.$store.commit(ACTION_SET_ANONYMOUS_LOGIN_INFO, localStorage.onsitelogin);
        }
        axios.get("manifest.json", { responseType: "json" }).then((response) => {
            this.version = response.data.version;
        });
    },
    computed: {
        showIcons() {
            return ["onsite-checkInOut", "onsite-visitTypeSelection"].indexOf(this.$route.name) > -1;
        },
    },
    methods: {
        startIdleTimer() {
            this.timeoutRef = setTimeout(this.goToStart, this.inactivityInterval);
        },
        stopIdleTimer() {
            clearTimeout(this.timeoutRef);
        },
        goToStart() {
            this.stopIdleTimer();
            if (this.$route.name !== "onsite-languageSelect") this.$router.push({ name: "onsite-languageSelect" });
        },

        poll() {
            this.$axios({
                url: `rest/v1/session/ping`,
                method: "get",
                responseType: "json",
                headers: {
                    Accept: "application/json",
                },
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.active === false) this.$router.push({ name: "onsite-home" });

                    this.pollRef = setTimeout(this.poll, this.pollInterval);
                })
                .catch(() => {
                    this.$router.push({ name: "onsite-home" });

                    this.pollRef = setTimeout(this.poll, this.pollInterval);
                });
        },
    },
};
</script>
